<template>
  <div>
    <NavBar
      style="max-height:50px;"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
         <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height:50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10">
            <div style="margin: 10px 20px 20px 50px ">
             <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              vertical-align: middle;
              ">
            <!-- <h3 style="text-align:left; margin-left:65px; margin-top:30px; font-weight:800; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">Add Suggestion</h3> -->
                 <div style="width: 30%" align="left">
                 <span style="font-weight: 800; padding-left: 8px;  font-size: 12pt;
                  text-transform: uppercase;   letter-spacing: 1px;">
                  <h3>Add Suggestion</h3>
                 </span>
                 </div>
              </div>  
            <v-card style="width:90%; margin-left:2px; height:100%; margin-top:20px;	height: 70vh; overflow:scroll; overflow-x: hidden;">
              <div style="display: flex; flex-direction: row; margin:10px" align="left">
                <div
                style="width: 100%; display: flex; flex-direction: column"
                align="left">

                <div style="display: flex; flex-direction: row; margin-top:5%">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Suggestion Name</p></div>
                  <div style="width: 50%" align="right">
                    <v-text-field
                      label="Enter name"
                      solo
                      v-model="suggestion_name"
                    ></v-text-field>
                  </div>
                </div>

                <div style="display: flex; flex-direction: row;">
                  <div style="width: 50%" align="left"><p  style="margin-top:12px; font-size:10pt; font-weight:600"> Suggestion Description</p></div>
                  <div style="width: 50%" align="right">
                    <v-textarea
                      label="Enter description"
                      solo
                      v-model="suggestion_desc"
                    ></v-textarea>
                  </div>
                </div>

                <div align="center">
                  <v-btn
                    @click="createSuggestion"
                    style=" width:50%; height: 40px; margin-right:10%; margin-top:20px;background-color: #1467BF;"
                    color="primary"
                    :disabled="isaddSuggestionClicked || !(suggestion_name.trim()
                    !=''&& suggestion_desc.trim() !='')"
                    >Create Suggestion</v-btn>
                </div>
                </div>
              </div>
            </v-card>
         </div> </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "CustomerHome",
  data() {
    return {
      currentUserName: "",
      currentUser: "",
      user_id: "",
      suggestion_name: "",
      suggestion_desc: "",
      pageName: "createSuggestion",
      isaddSuggestionClicked : false
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var getAccessData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        adminType: "ADMIN",
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/getAdmin", getAccessData)
        .then((getAdminResponse) => {
          this.currentUserName = getAdminResponse.data.data.admin_name;
          this.user_id = getAdminResponse.data.data._id;
        })
        .catch((getAdminError) => {
          if (getAdminError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          }
          else if(getAdminError.response.status == 400)
          {
            window.alert(getAdminError);
          }
        });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    createSuggestion() {
      this.isaddSuggestionClicked = true
      var CreatesuggestionBody = {
        suggestionName: this.suggestion_name.trim(),
        description: this.suggestion_desc.trim(),
        token: this.currentUser,
        typeOfUser: "ADMIN",
      };
      if (this.suggestion_name.trim() != "" && this.suggestion_desc.trim() != "") {
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/createSuggestion",
            CreatesuggestionBody
          )
          .then((createSuggestionResponse) => {
            alert("Suggestion created!!");
            this.$router.push({
              name: 'ViewAllSuggestions'
            });
          })
          .catch((createSuggestionError) => {
            this.isaddSuggestionClicked = true
            if (createSuggestionError.response.status == 401) {
              window.alert("Sorry !! You are unauthorized !!");
              this.$router.push({
                name: "AdminLogin",
              });
            }
            if (createSuggestionError.response.status == 305) {
              window.alert("Suggestion already exists");
            }
            else
            {
              window.alert("Something went wrong");
              console.log(createSuggestionError);
            }
          });
      } else {
        alert("Please provide all the details");
      }
    },
  },
};
</script>

  <style>
td,
th {
  padding: 10px;
}
#facebook{
  display: none;
}
</style>